import React from "react";
import { graphql } from "gatsby";
import PrivateLessonsTemplate from "../templates/private-lessons/private-lessons";

const environment = {
  lang: "nl",
  path: "/private-lessons",
  altHrefLang: [
    {
      lang: "nl",
      path: "/private-lessons",
    },
    {
      lang: "en",
      path: "/en/private-lessons",
    },
  ],
  theme: "green",
};

const PrivateLessonsNl = (props) => {
  return <PrivateLessonsTemplate environment={environment} {...props} />;
};

export default PrivateLessonsNl;

export const query = graphql`
  query videoPrivateLessonsNlQuery {
    video: contentfulAsset(
      contentful_id: { eq: "2F0Gv3vCsAsPWfYpQgti69" }
      node_locale: { eq: "nl" }
    ) {
      contentful_id
      file {
        url
      }
    }
  }
`;
